@charset "UTF-8";

@import "../mixin";

.gl-footer {
  margin: -55px 0 0 0;
  padding: 80px 0 0 0;
  position: relative;
  background: url("../../images/common/gl-footer-bg.png") repeat-x #fafaf0;
  &--inner {
    display: grid;

    grid-template-rows: 50px 1fr;
    grid-template-areas: "nav nav""headline support";
    grid-auto-columns: 590px 1fr;

    @include sp {
      overflow: hidden;
      display: block;
      background: url("../../images/common/gl-header-sp-menu-bg.png");
      padding-bottom: 50px !important;
      z-index: 1;
    }
  }
  &--items {
    position: absolute;
    top: -125px;
    left: calc(50% - 622px);
  }
  &--headline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    grid-area: headline;
  }
  &--logo {
    margin: 0 20px 0 0;

    @include sp {
      display: block;
      margin: 0 auto 10px;
    }
  }
  &--information {
    width: 327px;
    @include sp {
      display: block;
      width: 100%;
      margin-bottom: 15px;
      text-align: center;

    }
  }
  &--address {
    font-size: 14px;
  }
  &--tel {
    font-size: 25px;

    letter-spacing: 1px;
    &:before {
      content: $iconTel;
      display: inline-block;
      margin: 0 -3px 0 0;
      position: relative;
      top: 3px;
      font-size: 32px;

      @include icon;
    }
    a {
      color: $colorBrown;
      text-decoration: none;
    }
  }
}

.gl-footer_nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  padding: 0 0 15px 0;
  width: 860px;
  position: relative;
  grid-area: nav;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 500px);
    width: 1000px;
    height: 5px;
    background: url("../../images/common/border.png") repeat-x bottom left;
  }
  @include sp {
    width: 280px;
  }
  &--link {
    display: block;
    position: relative;
    color: $colorBrown;
    text-decoration: none;
    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 -3px 0 0;
      position: relative;
      top: 4px;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, .2);
      border-radius: 10px;

      transition: .3s cubic-bezier(.175, .885, .32, 1.275);
      transform: scale(.8, .8);
    }
    &:hover {
      &:after {
        opacity: 1;

        transform: scale(1.2, 1.2);
      }
    }
    @include sp {
      width: 50%;
      font-size: 14px;
      margin-bottom: 5px;
    }
    &-brown {
      &:before {
        background: url("../../images/common/gl-footer-nav-icon-brown.png") no-repeat;

        background-size: 20px 20px;
      }
    }
    &-green {
      &:before {
        background: url("../../images/common/gl-footer-nav-icon-green.png") no-repeat;

        background-size: 20px 20px;
      }
    }
    &-sub {
      &:before {
        width: 1px;
      }
    }
    &-middle {
      margin: 0 -12px;
    }
  }
  &--txt {
    position: relative;
    &-middle {
      &:before {
        content: "／";
      }
      &:after {
        content: "／";
      }
    }
  }
}

.gl-footer_support_nav {
  display: flex;
  width: 220px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: -10px 0 0 auto;

  grid-area: support;

  @include sp {
    margin: 0 auto;
    padding-bottom: 30px;
  }
  &--link {
    position: relative;
    font-size: 14px;
    line-height: .5;
    color: $colorBrown;
    text-align: center;
    text-decoration: none;
    &:before {
      display: block;
      font-size: 40px;

      @include icon;
    }
    &:after {
      content: "";
      display: block;
      width: 110%;
      height: 100%;
      position: absolute;
      top: 5px;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, .2);
      border-radius: 10px;

      transition: .3s cubic-bezier(.175, .885, .32, 1.275);
      transform: scale(.8, .8);
    }
    &:hover {
      &:after {
        opacity: 1;

        transform: scale(1.4, 1.2);
      }
    }
    &-contact {
      &:before {
        content: $iconTel;
        margin-bottom: 5px;
        font-size: 35px;
      }
    }
    &-access {
      &:before {
        content: $iconPin;
      }
    }
    &-facebook {
      &:before {
        content: $iconFacebook;
      }
    }
    &-blog {
      &:before {
        content: $iconBlog;
        margin: 0 0 11px 0;
        font-size: 32px;
      }
    }
  }
}

.gl-footer_copy {
  margin: 10px 0 0 0;
  padding: 15px 0;
  position: relative;
  background-color: $colorBrown;
  color: #fff;

  @include sp {
    margin-top: 0;
    padding-top: 80px;
  }
  &--inner {
    display: flex;
    flex-wrap: wrap;
  }
  &--txt {
    line-height: 1.6;
  }
  &--attention {
    font-size: 12px;

    @include sp {
      font-size: 10px;
    }
  }
  &--copy_txt {
    font-size: 14px;
  }
  &--link {
    display: block;
    margin: 5px 0 0 auto;
    font-size: 14px;
    color: #fff;
    text-align: right;
    text-decoration: none;

    @include sp {
      width: 100%;
      position: absolute;
      top: -25px;
      left: 0;
      text-align: center;
    }
    &:before {
      content: ">";
      display: inline-block;
      margin: 0 3px 0 0;
      position: relative;
      top: -1px;

      transform: scale(.6, 1);
    }
  }
}

.back_top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: block;
  z-index: 10;
  @include sp {
    position: absolute;
    bottom: 120px;
    right: calc(50% - 43px);
  }
}

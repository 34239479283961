@charset "UTF-8";

@import "../mixin";

.gl-header {
  position: relative;
  z-index: 2;

  border-top: 7px solid $colorBrown;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  &--inner {

    height: 148px;
    @include sp {
      display: block;
      height: auto;
    }
  }
  &--sp_menu_btn {
    display: none;
    width: 50px;
    height: 50px;
    padding: 5px;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 12px;
    background-color: $colorBrown;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;

    @include sp {
      display: block;
    }
    &.show {
      .gl-header {
        &--sp_menu_btn_border {
          &:nth-child(1) {
            top: 30px;

            transform: rotate(45deg);
          }
          &:nth-child(2) {
            left: 100%;
          }
          &:nth-child(3) {
            top: 30px;

            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  &--sp_menu_btn_border {
    display: block;
    width: 55%;
    height: 3px;
    position: absolute;
    left: 22%;
    background-color: #fff;
    border-radius: 10px;

    transition: .15s;
    &:nth-child(1) {
      top: 22px;
    }
    &:nth-child(2) {
      top: 30px;
    }
    &:nth-child(3) {
      top: 38px;
    }
  }
  &--logo {
    margin: 5px 0 0 0;
    display: block;
    width: 328px;
    position: absolute;
    @include sp {
      width: 200px;
      position: relative;
    }
  }
  &--logo_link {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    top: 2px;
    @include sp {
      top: -1px;
      width: 190px;
    }
  }
  &--logo_img {
    @include sp {
      width: 50px;
      height: auto;
    }
  }
  &--logo_txt {
    margin: 0 0 0 5px;

    @include sp {
      width: 120px;
    }
  }
  &--sp_menu {
    @include sp {
      display: none;
      width: 100%;
      padding: 30px 15px 100px;
      position: absolute;
      top: 65px;
      left: 0;
      opacity: 0;
      background: url("../../images/common/gl-header-sp-menu-bg.png");
      box-sizing: border-box;

      transition: opacity .3s cubic-bezier(.175, .885, .32, 1.275);
    }
    &.show {
      display: block;
    }
  }
}

.gl-support_nav {
  display: flex;
  width: 415px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 0 auto;
  position: absolute;
  right: 20px;
  top: 0;
  @include sp {
    width: 270px;
    margin: 15px auto 0;
    position: static;
  }
  &--link {
    display: block;
    height: 35px;
    padding: 10px 25px 0 44px;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: $colorBrown;
    text-decoration: none;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;

    border: 2px solid $colorBrown;
    border-top: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    &:before {
      position: absolute;
      top: -3px;
      left: 0;
      font-size: 40px;

      @include icon;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .15;
      background-color: $colorBrown;
      border-radius: 0 0 8px 8px;

      transition: height .2s cubic-bezier(.175, .885, .32, 1.275);
    }
    &:hover {
      &:after {
        height: 100%;
      }
    }
    @include sp {
      width: 33.3%;
      height: auto;
      padding: 0;
      text-align: center;

      border: 0;
      box-shadow: 0 0 0 0;
      &:before {
        display: block;
        margin: 0 auto;
        position: static;
        font-size: 45px;
      }
      &:after {
        display: none;
      }
    }
    &-contact {
      &:before {
        content: $iconTel;
        font-size: 33px;
        left: 8px;
        top: -1px;
      }
      @include sp {
        &:before {
          top: 5px;
          margin-bottom: 11px;
          position: relative;
        }
      }
    }
    &-access {
      &:before {
        content: $iconPin;
      }
    }
    &-blog {
      &:before {
        content: $iconBlog;
        font-size: 27px;
        top: 2px;
        left: 8px;
      }
      @include sp {
        &:before {
          font-size: 33px;
          margin: 7px 0 5px 0;
        }
      }
    }
  }
}

.gl-header_nav {
  display: flex;
  width: 612px;
  flex-wrap: wrap;
  margin: 0 0 0 auto;
  position: relative;
  top: 50px;

  @include sp {
    width: 100%;
    justify-content: space-between;
    top: 0;
    margin: 0;
  }
  &--link {
    display: block;
    position: relative;
    text-decoration: none;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 90%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: #fdeacc;
      border-radius: 10px;

      transition: .3s cubic-bezier(.175, .885, .32, 1.275);
      transform: scale(.8, .8);
    }
    &:hover {
      &:before {
        opacity: 1;

        transform: scale(1, 1.1);
      }
    }
    @include sp {
      width: calc(50% - 5px);
      margin: 10px 0 0 0;
      padding: 5px 0;
      background-color: #fff;
      border-radius: 10px;
      &:nth-child(1), &:nth-child(2) {
        margin-top: 0;
      }
      &:before {
        display: none;
      }
    }
    &.current {
      &:before {
        opacity: 1;

        transform: scale(1, 1.1);
      }
    }
    &-none {
      display: none;
      @include sp {
        display: block;
      }
    }
  }
  &--link_img {
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    @include sp {
      display: block;
      margin: 0 auto;
    }
  }
  &--tel {
    display: none;
    margin: 15px 0 0 0;
    font-size: 27px;
    text-align: center;

    @include sp {
      display: block;
    }
    &:before {
      content: $iconTel;
      position: relative;
      top: 3px;
      font-size: 32px;

      @include icon;
    }
    a {
      color: $colorBrown;
      text-decoration: none;
    }
  }
}

.contact_modal_window {
  display: none;
  position: relative;
  z-index: 100;
  color: #42210b;
  &--bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    z-index: 9;
  }
  &--meta {
    font-size: 20px;
    padding: 130px 0 0 0;
    margin: 0 0 30px 0;
    font-weight: bold;
    @include sp {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  &--contents {
    background: url('../../images/common/contact-modal-window-bg.png');
    width: 750px;
    height: 400px;
    position: fixed;
    left: calc(50% - 375px);
    top: calc(50% - 200px);
    z-index: 10;
    text-align: center;
    @include sp {
      background: url('../../images/common/contact-modal-window-bg-sp.png') no-repeat;
      width: 330px;
      left: calc(50% - 165px);
    }
  }
  &--tel {
    font-weight: bold;
    font-size: 35px;
    &:before {
      content: $iconTel;
      display: inline-block;
      position: relative;
      top: 3px;
      font-size: 39px;
      margin: 0 5px 0 0;
      @include icon;
    }
    @include sp {
      font-size: 20px;
      a {
        font-size: 20px;
        text-decoration: none;
        color: #42210b;
      }
      &:before {
        font-size: 29px;
      }
    }
  }
  &--close {
    width: 100px;
    height: 32px;
    line-height: 32px;
    display: block;
    color: #fff;
    text-align: center;
    position: absolute;
    left: calc(50% - 50px);
    bottom: 60px;
    background-color: #42210b;
    text-decoration: none;
    padding: 0 0 0 10px;
    transition: 0.15s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      border-bottom: 2px solid #fff;
      left: 7px;
      top: 13px;
      height: 2px;
      width: 20px;
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      display: block;
      border-bottom: 2px solid #fff;
      position: absolute;
      left: 6px;
      top: 13px;
      height: 2px;
      width: 20px;
      transform: rotate(-45deg);
    }
    &:hover {
      background-color: darken(#42210b, 20%);
    }
    @include sp {
      bottom: 140px;
    }
  }
}
@charset "UTF-8";

@import "mixin";
@import "common/gl-header";
@import "common/gl-footer";

.c-contents {
  padding: 0 0 260px 0;
  position: relative;
  background-color: #fafaf0;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    width: 1280px;
    height: 251px;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 622px);
    z-index: 1;
    background: url("../../images/common/gl-footer-items.png") no-repeat;
  }
  @include sp {
    padding-bottom: 200px;
    overflow: inherit;
    &:after {
      width: 90%;
      left: 5%;
      bottom: -30vw;
      background: url("../../images/common/gl-footer-items-sp.png") no-repeat;
      background-size: contain;
    }
  }
}
